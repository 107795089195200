import React from 'react';

const Styleguide = () => {
return (
	<div class="col-8">
            <h1>H1 title</h1>
            <h2>H2 title</h2>
            <h3>H3 title</h3>
            <h4>H4 title</h4>
            <p class="p1">P1 paragraph</p>
            <p class="p2">P2 paragraph</p>
            <p class="p3">P3 paragraph</p>
            <p class="p4">P4 paragraph</p>
            <p>
                <a href class="a1">A1 link 1</a>
            </p>
            <p>
                <a href class="a2">A2 link 2</a>
            </p>

            <p class="p2">Break 1</p>
            <div class="break"></div>
            <p class="p2">Break 2</p>
            <div class="break2"></div>
            <p class="p2">Break 3</p>
            <div class="break3"></div>
            <p class="p2">Break 4</p>
            <div class="break4"></div>
            <p class="p2">Break 5</p>
            <div class="break5"></div>
            <p class="p4">end</p>

    </div>
);
};

export default Styleguide;
