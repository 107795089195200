import React from "react";
import { Nav, NavLink, NavMenu }
	from "./NavbarElements";

const Navbar = () => {
return (
	<>
	<Nav>
		<NavMenu>

		{/* <div class="lean-right"> */}
			<NavLink to="/" activeStyle>
				Work
			</NavLink>
			<NavLink to="/play" activeStyle>
				Play
			</NavLink>
			{/* <NavLink to="/styleguide" activeStyle>
				Styleguide
			</NavLink> */}
			<NavLink to="/about" activeStyle>
				About
			</NavLink>
			<NavLink to={{pathname: "http://read.cv/tara"}} target="_blank" activeStyle>
				<a href="http://read.cv/tara" class="a3" target="_blank">CV</a>
			</NavLink>
		{/* </div> */}
		</NavMenu>
	</Nav>
	</>
);
};

export default Navbar;
