import React from 'react';

const LoopioLibrary = () => {
return (
	<div class="col-12">
		<h1>Loopio: Crafting Tools to Enable Better Library Health</h1>
		<img class="img-responsive" src={require('../assets/work/Loopio_Review/placeholder.png')} alt=""/>
		<div class="break4"></div>
		<p class="p1">
			Library management (or lack thereof) is a huge indicator of churn for Loopio's customers. As the beating heart of the platform, the content users must bring in and maintain must be sound and reliable, as it becomes the primary dataset to automate other areas of the platform. If a company find its library has spun out of control and there's no way to manage or review content regularly, then we see the end of our partnership here.
		</p>
        <p class="p1">
            In Q2-3 2022, I launched a series of impactful enhancements to reduce barriers to conducting reviews and understanding content utilization:
            <ul>
                <li>
                    Enabling the copying of styled content, images, and tables
                </li>
                <li>
                    Expanding content utilization scores to account for copied answers
                </li>
                <li>
                    Injecting bulk actions into the review process, reducing time, clicks, and frustration
                </li>
            </ul>
            </p>
		<div class="break4"></div>

		{/* At a Glance Section */}
		<hr/>
		<h2>At a Glance</h2>
		<p class="p2">
			<strong>Date:</strong> Q2 - Q3 2022
		</p>
		<p class="p2">
			<strong>Role:</strong> Lead Product Designer
		</p>
		<p class="p2">
			<strong>Skills:</strong> Stakeholder interviews, workshop facilitation, UI/UX design, rapid prototyping, conceptual testing
		</p>
		<p class="p2">
			<strong>Artifacts:</strong> Personas & JTBD, discussion guides, collaborative workshops, Figma designs & prototypes, user stories
		</p>
		<div class="break4"></div>
		<hr/>

		<div class="break4"></div>

		{/* Background & Opportunity */}
        <p class="p5">The Problem</p>
		<h2>
        Loopio's content health hinges upon regularly-scheduled reviews from SMEs (Subject Matter Experts). But why are reviews not being done?
        </h2>
		<p class="p2">
        At the time, Loopio's Library team was made of 2 primary pillars: <strong>Build</strong> and <strong>Trust</strong>. Build referred to the period where new customers would work with our onboarding consultants to set and hit library creation milestones (e.g. populate X entries, set Y review cycles).
		</p>
		<p class="p2">
        Trust, which this project fell under, referred to the maintenance period and structure after building a library. In order for content to be usable in projects (via automated entry population or manual usage), content had to be reliable and trustworthy; to be this meant customers needed a manageable way to review, audit, and edit existing entries.
		</p>
		<p class="p2">
        Review cycles, as they are currently set up, assume that SME (Subject Matter Experts) will review entries at a regular cadence. However, our metrics indicated that reviews were being left undone, with a lower performance level for our Enterprise customers. 
		</p>

        <div class="break3"></div>

        {/* Users */}
        <p class="p5">Understanding the Who</p>
        <h2>
        As a tool, Loopio is a web platform. But we see a number of users that interact within it/in the periphery.
        </h2>
        <div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_Review/users.png')} alt=""/>
        <div class="break3"></div>

        {/* <p class="p2">
        Trust, which this project fell under, referred to the maintenance period and structure after building a library. In order for content to be usable in projects (via automated entry population or manual usage), content had to be reliable and trustworthy; to be this meant customers needed a manageable way to review, audit, and edit existing entries.
        </p>
        <p class="p2">
        Review cycles, as they are currently set up, assume that SME (Subject Matter Experts) will review entries at a regular cadence. However, our metrics indicated that reviews were being left undone, with a lower performance level for our Enterprise customers. 
        </p> */}

        {/* Early research */}
        {/* <p class="p5">Kicking off Discovery</p>
        <h2>
        Using existing insights to drive early collaborative brainstorming and cross-department alignment
        </h2>
        <p class="p2">
        Despite knowing issues with library management, we didn't know exactly what was happening. Our goal was to uncover opportunities from customer interviews, and use those to shape the final project outcome.

        <ol>
            <li>
                I generated a preliminary report from our cross-department user feedback collection tool. I gleaned 9 primary themes around library management, using them to form a prelimary discussion guide.
            </li>
            <li>
                My PM and I ran a collaborative brainstorming session with the developers, to understand different perceptions on why reviews were left uncompleted, what success metrics could look like, and what risks/limitations to be aware of.
            </li>
        </ol>
        </p> */}

        {/* Early research */}
        <p class="p5">Metrics</p>
        <h2>
        Measuring success & defining impact
        </h2>
        <p class="p2">
        Our leading questions were:

        <ul>
            <li>
            How might we enable customers to proactively manage their workload, minimizing unnecessary and overdue reviews?
            </li>
            <li>
            How might we help reduce the percentage of overdue reviews in our library? (our measure of success)
            </li>
        </ul>
        </p>

        <p class="p2">
            In the grand scheme of impact however, library review management was the 7th-most popular idea, with 179 requesting accounts, yielding a potential $6.6 million ARR impact.
        </p>

        <div class="break4"></div>
		<img class="img-responsive" src={require('../assets/work/Loopio_Review/graphs.png')} alt=""/>
        <div class="break3"></div>


    {/* Round 1 */}
        <p class="p5">Round 1 Insights</p>
        <h2>
        How were our assumptions of the problem space validated or challenged?
        </h2>

        <p class="p2">
            Before starting with customer research, my PM and I ran a collaborative brainstorming session with the developers, to understand different perceptions on why reviews were left uncompleted, what success metrics could look like, and what risks/limitations to be aware of.
        </p>

        <div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_Review/discussion_guide.png')} alt=""/>
        <div class="break4"></div>

        <p class="p2">
        Incorporating feedback into our first discussion guide, we ran 2 rounds of user-facing sessions; the first one was to better understand blockers in reviewing content, and the second was a highly flexible conceptual testing period.
        </p>
        <p class="p2">
        In our first round of questioning (n=10; 7 admin users and 3 SMEs), a series of insights emerged:
        <ul>
            <li>
                Users lacked <strong>visibility and transparency</strong> about the state of their library and upcoming/ongoing reviews.
            </li>
            <li>
                Users struggled with the massive time sink of library management; they wanted more <strong>bulk actions and prioritization</strong> features.
            </li>
            <li>
                There was no way to gauge <strong>accurate content usage</strong>, which would help prioritize what entries should be reviewed (instead of spamming someone to review them all).
            </li>
        </ul>
        </p>
        {/* <p class="p2">
            In the grand scheme of impact however, library review management was the 7th-most popular idea, with 179 requesting accounts, yielding a potential $6.6 million ARR impact.
        </p> */}

        <div class="break3"></div>
		{/* <div class="img-placeholder"></div>
        <div class="break3"></div> */}

        {/* Copy */}
        <p class="p5"> Addressing surefire wins</p>
        <h2>
            Adding copy usage into the content usage score
        </h2>
        <p class="p2">
        At the time, I had recently made a small fix on the platform. We heard numerous requests to allow the copying of rich text, as users found value in using stored entries in emails, documents, etc. At the time, we only copied out plain text, which meant the answer had been stripped of images, styling, footnotes, tables, etc. upon copy. 
        </p>
        <div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_Review/review1.png')} alt=""/>
        <div class="break4"></div>
        <p class="p2">
        This small enhancement turned into the hottest topic for our company in 2022, as it drastically improved the workflow of all of our customers.
        </p>
        {/* <p class="p2">
        IMAGE NEEDED
        </p> */}

        <p class="p2">
        However, small this was, we also had to look at what this changes. Our platform tracked entry usage in projects. But this was arguably another way of content utilization. So it was no question for us to reflect this new use case across the platform.
        </p>

        <div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_Review/copy_usage.gif')} alt=""/>
        <div class="break4"></div>

        {/* <div class="break4"></div> */}
        <img class="img-responsive" src={require('../assets/work/Loopio_Review/review2.png')} alt=""/>
        <div class="break3"></div>


           {/* Round 2 */}
           <p class="p5">Round 2 Insights</p>
        <h2>
        Working at rapidfire speed to test changing concepts daily
        </h2>

        <p class="p2">
        Increasing visibility into how library entries were used was a no brainer. Validating the other 2 concepts was not. Given multiple requests for reporting visibility, we started with sketches for library entry and review cycle management. However, after going through numerous validation sessions, none of them stuck. It could've been because of the sample users we were validating with (not Enterprise), or we just hadn't thought of the right way to model information.
        </p>


        <div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_Review/iterations.gif')} alt=""/>
        <div class="break4"></div>
        <p class="p2">
        We ended up scrapping the reporting-based work and went with the other primary insight that resonated with users - enabling bulk actions on the reviews page.
        </p>
        <div class="break4"></div>
        {/* <img class="img-responsive" src={require('../assets/work/Loopio_Review/bulk_ss.png')} alt=""/>
        <div class="break4"></div> */}
        {/* <div class="break4"></div> */}
        <img class="img-responsive" src={require('../assets/work/Loopio_Review/bulk.gif')} alt=""/>
        <div class="break4"></div>

        <p class="p2">
        At the end of the day, our concept testing period only took a sprint's worth of investigation time. My developers were unaffected as they were implementing usage score work, and we were able to pivot and share bulk action work quickly.
        </p>
        
        <div class="break3"></div>

        {/* Copy */}
        <p class="p5"> Measuring Impact</p>
        <h2>
            How did we change review management for Enterprise customers?
        </h2>
        <p class="p2">
        Something that I still remember during the first round of customer validation, was a user mentioning the numerous clicks he went through to review entries 1 by 1. "I'm going to get carpal tunnel from this."
        </p>
        
        <p class="p2">
        We launched Bulk Actions in February, so the impact of the work wasn't collected in time for the 2022 report. But according to the cursory report I pulled pre-project, this request alone had a $2M pricetag on it.
        </p>

        <div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_Review/feedback.png')} alt=""/>
        <div class="break4"></div>

        <p class="p2">
        The impact of usage scores and rich copy has been much more palpable.
        </p>

        <div class="break3"></div>

        {/* End */}
        <p class="p5"> In Conclusion</p>
        <h2>
            Thoughts & Reflection
        </h2>
        <p class="p2">
        I remember being the slightest bit overwhelmed when doing the second round of conceptual testing. Things were changing daily - maybe even every half day. But I worked closely with my PM to ensure 100% alignment before jumping into every customer validation session, and we would meet regularly to co-design and bounce new ideas off of each other. The fast feedback--in fact, the fast validation that we were looking in the wrong direction--was invaluable for the success of our later work.
        </p>

	</div>
);
};

export default LoopioLibrary;
