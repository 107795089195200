// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import {useEffect} from 'react';


import './App.css';
import Navbar from './components/Navbar';
// import { BrowserRouter as Router, Routes, Route}
// 	from 'react-router-dom';
import { Routes, Route, HashRouter } from "react-router-dom";

import Home from './pages';
import About from './pages/about';
import Play from './pages/play';
import SignUp from './pages/signup';
import Contact from './pages/contact';
import Styleguide from './pages/styleguide';
import ScrollToTop from'./ScrollToTop';


import LoopioNFR from './works/Loopio_NFR';
import LoopioLibrary from './works/Loopio_Proactive_Review_Management';
import LoopioImport from './works/Loopio_Import';
import LoopioAccessibility from './works/Loopio_Accessibility';
import MedMeFigma from './works/MedMe_Figma';
import MedMePatient from './works/MedMe_Patient_Intake';

import QuantifiedSelf from './play/2018visualizations';
import WearableMedia from './play/Wearable_Media';

// window.scrollTo({
//   top: 0,
//   left: 0,
//   behavior: 'smooth'
// });

function App() {

  // useEffect(() => {
  //   window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  // }, []);

return (
  <div class="parent-container">
    <HashRouter>
    <ScrollToTop />
    <Navbar />

    <div class="break"></div>
    <Routes>
      <Route exact path='/' exact element={<Home />} />

      {/* Work projects */}
      {/* <Route path='/LoopioNFR' element={<LoopioNFR/>} /> */}
      <Route path='/LoopioReviewManagement' element={<LoopioLibrary/>} />
      <Route path='/LoopioImport' element={<LoopioImport/>} />
      <Route path='/LoopioAccessibility' element={<LoopioAccessibility/>} />
      <Route path='/MedMeFigmaLibrary' element={<MedMeFigma/>} />
      <Route path='/MedMePatientIntake' element={<MedMePatient/>} />

      {/* Play projects */}
      <Route path='/2018visualizations' element={<QuantifiedSelf/>} />
      <Route path='/WearableMedia' element={<WearableMedia/>} />

      <Route path='/styleguide' element={<Styleguide/>} />
      <Route path='/about' element={<About/>} />
      {/* <Route path='/contact' element={<Contact/>} /> */}
      <Route path='/play' element={<Play/>} />
      <Route path='/sign-up' element={<SignUp/>} />
    </Routes>

    <Contact />
    </HashRouter>
  </div>
);

}



export default App;
