import React from "react";

const About = () => {
return (
    <div>
        <div class="col-12">
            <h1>
                <img src={require('../assets/wave.png')} alt="waving emoji"/> &nbsp;
                <img src={require('../assets/wave.png')} alt="waving emoji"/> &nbsp;
                <img src={require('../assets/wave.png')} alt="waving emoji"/> &nbsp;
                {/* <img src={require('../assets/wave.png')} alt="waving emoji"/> &nbsp;
                <img src={require('../assets/wave.png')} alt="waving emoji"/> */}
            </h1>
            <p class="p1">
                {/* I'm a Toronto-based product designer with a diverse background in engineering and critical theory. My background allows me to seamlessly operate at the intersection of design, research, and strategy; I love getting my hands dirty with large, conceptual problems and translating them into thoughtful and intentional solutions. */}
                Before SurveyMonkey, I was a designer at Loopio&mdash;a response management and content management platform that facilitates winning bids for Enterprises. I led the future of content management and review workflows for updated, trustworthy, and usable Libraries.
            </p>
            <p class="p1">
                {/* I currently work at Loopio, building content management tools to empower the everyday user. I also work with OCAD University to develop best design & evaluation practices for emerging hybrid systems, e.g. the Metaverse.  */}
                I'm a budding accessibility advocate. I took pride in leading company-wide discourse and enabling a cross-team practice at Loopio, and look for ways to expand my knowledge and community.
            </p>
            <p class="p1">
                {/* Outside of work, I’m pretty hardcore about a number of hobbies. I love lifting, cooking, illustrating, and knitting and am always excited to chat about them. */}
                In a previous life, I worked in healthcare. I was the founding designer at <a href="http://medmehealth.com" class="a1">MedMe Health (YC 21)</a>, where I designed the Rexall and Shoppers pharmacy intake flow, providing care to 7M+ patients despite the uncertainty of COVID-19.
            </p>
            <div class="break2"></div>
        </div>
        {/* <div class="row">
            <div class="col-12">
            <h3>SOME SKILLS IN ACTION</h3>
            </div>
        </div> */}
        <div class="row">
            <div class="col-6">
                <img class="img-responsive" src={require('../assets/about/workshop.png')} alt="Girl with bobbed hair holding up and reading off papers during a co-creation workshop session."/>
                    <div class="home-project-caption">
                        <p class="p4">
                            Workshop Design & Facilitation
                        </p>
                        <h4>
                            Stakeholder alignment & collaboration
                        </h4>
                        <p class="p2">
                        With a strong focus on cross-team collaboration, I prioritize inclusive design by facilitating engaging virtual and in-person workshops that uncover opportunities, address problems, incorporate technical limitations, and foster alignment among stakeholders, driving successful project outcomes.
                        </p>
                        <div class="break3"></div>
                    </div>
            </div>
            <div class="col-6">
                {/* <div class="img-placeholder"/> */}
                <img class="img-responsive" src={require('../assets/about/accessibility.png')} alt="Accessibility annotations marked up on the mockups of this website."/>
                    <div class="home-project-caption">
                        <p class="p4">
                            Accessibility
                        </p>
                        <h4>
                            Providing equitable access to information
                        </h4>
                        <p class="p2">
                        Being deeply invested in accessibility, I start my designs with accessibility annotations in my work, and end my involvement through testing with assistive technology. I stay updated on accessibility trends and foster intentional learning experiences to mobilize knowledge across departments and teams for a more inclusive design approach.
                        </p>
                        <div class="break4"></div>
                    </div>
                </div>
        </div>
        <div class="break3"></div>
        <div class="row">
            <div class="col-6">
            <img class="img-responsive" src={require('../assets/about/UXR.png')} alt="Girl with bobbed hair walking users through a digital prototype on her laptop. A Caucasian woman is taking notes of user feedback."/>
                    <div class="home-project-caption">
                        <p class="p4">
                            UX Research
                        </p>
                        <h4>
                            Advocating for users first
                        </h4>
                        <p class="p2">
                        I am deeply involved in diverse research activities, from crafting discussion guides and leading interviews and synthesis sessions to conducting usability testing and piecing together cultural probes, while also integrating business metrics to inform research goals and deliver valuable insights.
                        </p>
                        <div class="break3"></div>
                    </div>
            </div>
            <div class="col-6">
            <img class="img-responsive" src={require('../assets/about/product.png')} alt="A virtual whiteboard collection of sticky notes, meant to prioritize customer asks for an upcoming tech feature."/>
                    <div class="home-project-caption">
                        <p class="p4">
                            Product & UX Design
                        </p>
                        <h4>
                            Balancing business and user needs
                        </h4>
                        <p class="p2">
                        I employ a comprehensive range of artifacts including personas, scenarios, user flows, and IA tools to effectively communicate my work, ensuring it aligns with business objectives and drives impactful outcomes.
                        </p>
                        <div class="break4"></div>
                    </div>
                </div>
        </div>
        <div class="break3"></div>
        <div class="row">
            <div class="col-6">
            <img class="img-responsive" src={require('../assets/about/service.png')} alt="Flow diagram of an existing feature, including user notes and areas of concern."/>
                    <div class="home-project-caption">
                        <p class="p4">
                            Service Design
                        </p>
                        <h4>
                            Investigating all touchpoints
                        </h4>
                        <p class="p2">
                            I thoughtfully consider all actors and touchpoints, engaging in co-design to uncover knowns, address unknowns, and create end-to-end solutions that are mutually beneficial for all.
                        </p>
                        <div class="break3"></div>
                    </div>
            </div>
            <div class="col-6">
            <img class="img-responsive" src={require('../assets/about/visual.png')} alt="A screenshot of permutations of form components, meant to illustrate atomic design and the formation of molecules."/>
                    <div class="home-project-caption">
                        <p class="p4">
                            Visual & Interaction Design
                        </p>
                        <h4>
                            The devil in the details
                        </h4>
                        <p class="p2">
                        With an innate eye for aesthetics and a passion for self-learning, I effortlessly produce visually captivating work, despite my lack of traditional design education.
                        </p>
                        <div class="break3"></div>
                    </div>
                </div>
        </div>
    </div>
);
};

export default About;
