import React from 'react';
import { BrowserRouter as Router, Routes, Route}
	from 'react-router-dom';
import {Link} from "react-router-dom";

// import {wave} from "../assets/wave.png";


const Play = () => {
return (
	<div>
        <div class="col-7">
	        <h2>
                This is my playground.
                {/* <img src={require('../assets/wave.png')} alt="waving emoji"/> */}
            </h2> 
            <p class="p1">
            These range from one-off projects to coursework, to passion projects built over time and practice.
            </p>
            <div class="break2"></div>
         </div>

    <div class="row">
        <div class="col-6">
            {/* <div class="img-placeholder"></div> */}
            <img class="img-responsive" src={require('../assets/play/cal9.jpg')} alt="A photo of soil and greenery from a hillside (Hawk Hill, San Francisco, California)."/>
                <div class="home-project-caption">
                    <p class="p4">
                        Personal (2016 - Ongoing)
                    </p>
                    <h4>
                        Around the World
                    </h4>
                    <p class="p2">
                        For my travels in the past few years, I’ve created a visual log to showcase the photos I take.
                    </p>
                    <p class="p2">
                        <a class="a1" href="http://aroundtheworld.taratsang.com" target="_blank">
                            View Project
                        </a>
                    </p>
                    <div class="break3"></div>
                </div>
            </div>
        <div class="col-6">
            {/* <div class="img-placeholder"></div> */}
            <img class="img-responsive" src={require('../assets/play/4.JPG')} alt="A photo of soil and greenery from a hillside (Hawk Hill, San Francisco, California)."/>
                <div class="home-project-caption">
                    <p class="p4">
                        Personal (2020 - Ongoing)
                    </p>
                    <h4>
                        Sketchbook Archives: Digital & Drawn Illustrations
                    </h4>
                    <p class="p2">
                    A small collection of digital & analog drawings over the years.
                    </p>
                    <p class="p2">
                        <a class="a1" href="http://create.taratsang.com" target="_blank">
                            View Project
                        </a>
                    </p>
                    <div class="break3"></div>
                </div>
            </div>
        </div>
    {/* <br/> */}
    {/* <div class="break3"></div> */}
    <div class="row">
        {/* <div class="col-6">
            <div class="img-placeholder"></div>
                <div class="home-project-caption">
                    <p class="p4">
                        University of Waterloo (2018)
                    </p>
                    <h4>
                        Creating Dualities through Wearable Media (2018)
                    </h4>
                    <p class="p2">
                    Exploring physical interactions and relationships via the world of wearable technology.
                    </p>
                    <Link to="/WearableMedia" activeStyle>
                        <p class="p2">
                            <a class="a1">
                                View Case Study
                            </a>
                        </p>
                    </Link>
                    <div class="break3"></div>
                </div>
            </div> */}
        <div class="col-6">
            {/* <div class="img-placeholder"></div> */}
            <img class="img-responsive home-img" src={require('../assets/play/2018/jan1.jpg')} alt=""></img> 
                <div class="home-project-caption">
                    <p class="p4">
                        Personal (2018)
                    </p>
                    <h4>
                        Data Visualizations: The Quantified Self
                    </h4>
                    <p class="p2">
                        I try to analyze and quantify myself in a series of habits and visualizations.
                    </p>
                    <Link to="/2018visualizations" activeStyle>
                        <p class="p2">
                            <a class="a1">
                                View Case Study
                            </a>
                        </p>
                    </Link>

                </div>
            </div>
        </div>
<br/>
{/* <br/>
    <Link to="/LoopioImport" activeStyle>
        Loopio Import
    </Link> */}


	</div>
);
};

export default Play;
