import React from 'react';

const Contact = () => {
return (
	<div class="footer col-12">
		<li class="footer-link">
			<ul>
				<p><a href="" class="a2">hello@taratsang.com</a></p>
			</ul>
			<ul>
				<p><a href="" class="a2">Linkedin</a></p>
			</ul>
		</li>
	</div>
);
};

export default Contact;
