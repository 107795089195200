import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

// export const Nav = styled.nav`
// background: #ffb3ff;
// height: 85px;
// display: flex;
// justify-content: space-between;
// padding: 0.2rem calc((100vw - 1000px) / 2);
// z-index: 12;
// `;

export const Nav = styled.nav`
padding-top: 1.2em;
padding-bottom: 5em;
font-family: var(--sans), Lato, sans-serif;
top:0;
position: absolute; 
width:70%;
transition: top 0.3s; 
z-index:1;
// background-color:pink;
`;

export const NavLink = styled(Link)`
color: #000;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&.active {
	color: #000;
	text-decoration:underline;
}
`;

export const Bars = styled(FaBars)`
display: none;
color: #808080;
@media screen and (max-width: 768px) {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-100%, 75%);
	font-size: 1.8rem;
	cursor: pointer;
}
`;

// export const NavMenu = styled.div`
// display: flex;
// align-items: center;
// margin-right: -24px;
// padding-left:540px;
// background-color:blue;
// /* Second Nav */
// /* margin-right: 24px; */
// /* Third Nav */
// /* width: 100vw;
// white-space: nowrap; */
// @media screen and (max-width: 768px) {
// 	display: none;
// }
// `;

export const NavMenu = styled.div`
display: flex;
align-items: center;
float:right;
@media screen and (max-width: 320px) {
	// background:yellow;
}
@media screen and (min-width: 321px) and (max-width: 480px) {
	// background:orange;
	margin-right:-15vw;
}
@media screen and (min-width: 481px) and (max-width: 768px) {
	// background:red;
	// margin-right:-75px;
	margin-right:-15vw;
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
	// background:pink;
	// margin-right:-120px;
	margin-right:-15vw;
}
@media screen and (min-width: 1023px) and (max-width: 1200px) {
	// background:purple;
	// margin-right:-150px;
	margin-right:-15vw;
}
@media screen and (min-width: 1201px) and (max-width: 1440px) {
	// background:green;
	// margin-right:-200px;
	margin-right:-12vw;
}
@media screen and (min-width: 1441px) {
	// background:gray;
	// margin-right:-10vw;
	margin-right:200px;
}
`;
