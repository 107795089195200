import React from 'react';
import { BrowserRouter as Router, Routes, Route}
	from 'react-router-dom';
import {Link} from "react-router-dom";

// import {wave} from "../assets/wave.png";


const Home = () => {
return (
	<div>
        <div class="col-7">
	        <h1>
                Hi, I'm Tara! &nbsp;
                <img src={require('../assets/wave.png')} alt="waving emoji"/>
            </h1> 
            <p class="p1">
                I’m a product designer at <a href="http://surveymonkey.com" class="a1" target="_blank">SurveyMonkey</a>, empowering the everyday user to make the decisions of tomorrow with confidence and clarity.
            </p>

            {/* <p class="p1">
                Currently learning meaningful <a class="a1" href="https://www.senecacollege.ca/programs/workshops/UWX.html" target="_blank">UX writing</a> practices, interactive prototyping with <a class="a1" href="https://www.framer.com/" target="_blank">Framer</a>, and <a class="a1"  href="https://abbycovert.com/make-sense/" target="_blank">making sense</a> of mess. 
            </p> */}
            <div class="break2"></div>
         </div>
        <div class="row">
            <div class="col-6">
                {/* <div class="img-placeholder"></div>
                <div class="home-project-caption">
                    <p class="p4">
                        Loopio (2023) - COMING SOON
                    </p>
                    <h4>
                        Meeting sales professionals where they're at
                    </h4>
                    <p class="p2">
                        I unleashed the potential of non-formal response expertise to empower sales professionals ... their unique workflows
                        .
                    </p>
                    <Link to="/" activeStyle>
                        <p class="p2">
                            <a class="a1 disabled">
                                Coming Soon
                            </a>
                        </p>
                    </Link> */}
                {/* <div class="img-placeholder"></div> */}
                <img class="img-responsive home-img" src={require('../assets/work/Loopio_Review/placeholder.png')} alt=""></img>
                <div class="home-project-caption">
                    <p class="p4">
                        Loopio (2022)
                    </p>
                    <h4>
                        Crafting Tools to Enable Better Library Health
                    </h4>
                    <p class="p2">
                        I designed features to enable proactive review management, streamlining decision-making for library maintenance.
                    </p>
                    <Link to="/LoopioReviewManagement" activeStyle>
                        <p class="p2">
                            <a class="a1">
                                View Case Study
                            </a>
                        </p>
                    </Link>
                </div>
                <div class="break3"></div>
            </div>
            <div class="col-6">
                {/* <div class="img-placeholder"></div> */}
                <img class="img-responsive home-img" src={require('../assets/work/Loopio_LWSI/placeholder1.png')} alt=""></img>
                <div class="home-project-caption">
                    <p class="p4">
                        Loopio (2022)
                    </p>
                    <h4>
                        Enabling a More Seamless Migration of Library Content Upfront
                    </h4>
                    <p class="p2">
                        I used service design to streamline the library import experience for enterprise customers with complex document styles.
                        {/* Co-creating internal onboarding tools for enterprise customers with complicated styleguides. */}
                    </p>
                    <Link to="/LoopioImport" activeStyle>
                        <p class="p2">
                            <a class="a1">
                                View Case Study
                            </a>
                        </p>
                    </Link>
                </div>
                <div class="break3"></div>
            </div>
    </div>
    {/* <div class="break3"></div> */}
    <div class="row">
        <div class="col-6">
            {/* <div class="img-placeholder"></div> */}
            <img class="img-responsive home-img" src={require('../assets/work/Loopio_a11y/placeholder.png')} alt=""></img>
                <div class="home-project-caption">
                    <p class="p4">
                        Loopio (2021-2023)
                    </p>
                    <h4>
                        Driving an Accessible Component Library (and more)
                    </h4>
                    <p class="p2">
                        Simultaneously becoming the accessibility SME to mobilize design learnings + pushing for a WCAG AA compliant component library.
                    </p>
                    <Link to="/LoopioAccessibility" activeStyle>
                        <p class="p2">
                            <a class="a1">
                                View Case Study
                            </a>
                        </p>
                    </Link>
                </div>
                <div class="break3"></div>
            </div>
        <div class="col-6">
            {/* <div class="img-placeholder"></div> */}
            <img class="img-responsive home-img" src={require('../assets/work/MedMe_Figma/placeholder.png')} alt=""></img>
            <div class="home-project-caption">
                <p class="p4">
                    MedMe Health (2021)
                </p>
                <h4>
                    Defining a (Figma) Component Library for an Early-Stage Startup
                </h4>
                <p class="p2">
                Laying the foundations of patient and pharmacist-facing design libraries for future designers.
                </p>
                <Link to="/MedMeFigmaLibrary" activeStyle>
                    <p class="p2">
                        <a class="a1">
                            View Case Study
                        </a>
                    </p>
                </Link>
            </div>
            <div class="break3"></div>
        </div>  
    </div>
    {/* <div class="break3"></div> */}
    <div class="row">
    <div class="col-6">
            {/* <div class="img-placeholder"></div> */}
            <img class="img-responsive home-img" src={require('../assets/work/MedMe_Intake/placeholder.png')} alt=""></img>
            <div class="home-project-caption">
                <p class="p4">
                    MedMe Health (2020)
                </p>
                <h4>
                    Shifting Community Pharmacy Paradigms to Make Healthcare Accessible
                </h4>
                <p class="p2">
                    Reducing friction to enable access to care during a global health crisis.
                </p>
                <Link to="/MedMePatientIntake" activeStyle>
                    <p class="p2">
                        <a class="a1">
                            View Case Study
                        </a>
                    </p>
                </Link>
            </div>
            <div class="break3"></div>
        </div>
    
        <div class="col-6">
            {/* <div class="img-placeholder"></div> */}
            <img class="img-responsive home-img" src={require('../assets/work/Bridgeable/brochure.jpg')} alt=""></img>
            <div class="home-project-caption">
                <p class="p4">
                    Bridgeable (2019)
                </p>
                <h4>
                    Guiding Youth Home
                </h4>
                <p class="p2">
                A service design approach to reducing youth homelessness in Hamilton, Ontario.
                </p>
                {/* <Link to="/LoopioImport" activeStyle> */}
                    <p class="p2">
                        <a class="a1" href="https://www.bridgeable.com/designership_posts/" target="_blank">
                            View Case Study (External)
                        </a>
                    </p>
                {/* </Link> */}
            </div>
            </div>
        </div>
	</div>
);
};

// var count=1;
// var password; 
// var pass="pharmacy2020"; 
// password=prompt('Enter Password',' '); 

// while (count>0) {
//     if (password==pass) {
//         count=0;
//     }
//     else {
//         alert('Please try again.');
//         password=prompt('Enter Password',' ');
//     }
// }  
// alert('Correct! Click OK to Enter.'); 


export default Home;
