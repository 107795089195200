import React from 'react';

const MedMePatient = () => {
return (
	// <div class="parent-container">
	<div>
		<div class="col-12">
			<h1>MedMe Health: Shifting the Paradigm of Accessible Care</h1>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/placeholder.png')} alt=""/>
			<div class="break4"></div>
			<p class="p1">
				For my first project at MedMe Health, I worked as the designer and PM while engaging with a team of 4 developers and 1 clinical team member to overhaul the existing patient-facing side of the platform. I untangled the intricacies of designing a modular intake flow for multiple clinical services with varying provincial requriements, and translated it into an intuitive, accessible intake experience for patients accessing care.
			</p>
			<p class="p1">
				At the time, the design directly impacted our ability to handle flu shot sign-ups. As of late 2021, my redesigned intake process has supported over 7 million unique patients as they signed up for COVID-19 vaccines across Canada.
			</p>
			<div class="break4"></div>

			{/* At a Glance Section */}
			<hr/>
			<h2>At a Glance</h2>
			<p class="p2">
				<strong>Date:</strong> Nov - Dec 2020
			</p>
			<p class="p2">
				<strong>Role:</strong> Solo Product Designer
			</p>
			<p class="p2">
				<strong>Skills:</strong> Design audit, systems design, cross-team collaboration
			</p>
			<p class="p2">
				<strong>Artifacts:</strong> Design audit documentation, component library (Figma), design guidelines
			</p>
			<div class="break4"></div>
			<hr/>

			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/user_complaint.png')} alt=""/>
			<div class="break3"></div>

			{/* Background & Opportunity */}
			<p class="p5">THE PROBLEM</p>
			<h2>Intake forms and booking pages are amongst the first touchpoints a patient encounters when attempting to access medical services. Poor UX becomes a barrier to timely care.</h2>

			<p class="p2">
				When I did my introductory usability analysis of the MedMe platform, I noticed that the patient intake side of the platform was long, unwieldy. After chatting with pharmacies on our support system (Intercom), it became clear that it wasn’t just me who struggled with it—patients also encountered much confusion with wording, flow, and the single-question nature of the sign-up pages.
			</p>
			<p class="p2">
			My focus on this redesign was not only to streamline the collection of information, but to introduce accessibility into the design culture at MedMe. The original design had failed to consider conventional form UX for older audiences, which was one of the target audience MedMe serves. To streamline the sign-up process, I focused on eliminating unnecessary clicks, strategically chunking sections, and reworking copy and error messages to reduce unnecessary mental load.
			</p>

			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/old_flow_large.png')} alt=""/>
			<div class="break3"></div>

			{/* Defining Success */}
			<p class="p5">Goals/Success Metrics</p>
			<h2>How can we create a more delightful intake process for patients accessing pharmacy care?</h2>

			<p class="p2">
				After gathering patient feedback, it became clear there were a number of problems with the flow. I crafted patient-facing metrics for success in order to gauge the results of the overhaul:
				<ul>
					<li>
						Number of clients using the platform (although this heavily depended on the services offered at the time, e.g. flu shots vs. COVID-19 vaccines)
					</li>
					<li>
						Average time spent signing up a single patient for an appointment
					</li>
					<li>
						Number of pages/clickthroughs required for a single sign-up
					</li>
					<li>
						Any qualitative feedback received from patients, be it through existing communication channels, pharmacy feedback, or direct emails/phonecalls
					</li>
				</ul>
			</p>

			<div class="break3"></div>

			{/* Internal Research and Audit */}
			<p class="p5">Analyzing existing workflows</p>
			<h2>The current state of the platform</h2>

			<p class="p2">
				I started by examining on the workflow of the current design by mapping pages and counting clicks. For a single patient flu-shot sign-up, it took at least 16 pages (depending on if we included COVID screening) and on average, took 5-8 minutes to complete.
			</p>
			<p class="p2">
				For multi-patient appointments (e.g. flu shots), multiple steps would repeat for subsequent patient you were signing up for.
			</p>
			<p class="p2">
				Frankly speaking, this was pretty terrible. We had received a lot of feedback from elderly patients citing that they were confused by wording and many gave up on signing, the flow was highly unintuitive, the process was long and unwieldy, and obscured elements and wonky scrolls meant they couldn't answer questions well.
			</p>
			<p class="p2">
				Ultimately, the long time it took to successfully complete one sign-up meant that oftentimes, patients would lose the time they initially chose and would have to re-select a less ideal one at the very end.
			</p>
				
			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/old_flow.png')} alt=""/>
			<div class="break3"></div>

			{/* External Research */}
			<p class="p5">Studying Competitors</p>
			<h2>What is everyone else doing?</h2>

			<p class="p2">
				The second thing I did was to conduct market research and testing sign-up flows with multiple competitors. After all, MedMe wasn't the only service being used for pharmacies for flu shot sign-ups. What was Costco using? What was Shoppers using? Was there a sticky quality to them that we could adopt for our use case?
			</p>
			<p class="p2">
				I examined over 9 competitor sites to see what information they gathered and in what order. From here, I was able to pick out the pros and cons of each platform by analyzing the general intake flow, whether or not accounts were needed, if they support multi-patient sign-ups, etc. This allowed me to have a better idea of how certain features and flows worked together and assisted in determining what should stay/go in the overhauled platform.
			</p>
		
			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/confluence.png')} alt=""/>
			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/feedback1.png')} alt=""/>
			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/companalysis.gif')} alt=""/>
			<div class="break3"></div>

			{/* Iterative Designs */}
			<p class="p5">Exploring Designs</p>
			<h2>Rethinking the intake process</h2>

			<p class="p2">
				From here, I translated my findings into two rounds of wireframes to illustrate my ideas. I also created other design documentation to help communicate things clearly (e.g. a spreadsheet to illustrate the complex requirements for different provinces and appointment types.)
			</p>
			<p class="p2">
				After the first round, I presented to the clinical and leadership teams for feedback and discussion over concerns. One thing that was important to consider was the modularity of the intake process—while this redesign was primarily for the existing horde of flu shot appointments, it would have to carry over to other clinical services. Certain services required much more information, some less, and different provinces and clients would further require another layer of customization.
			</p>
			<p class="p2">
			After integrating the first round of clinical feedback into my second draft, I scheduled a critique session with our technical team to sync regarding technical feasibility.
			</p>

			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/excel.png')} alt=""/>
			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/old_mocks.png')} alt=""/>
			<div class="break3"></div>

			<p class="p2">
			At this point, I worked closely with the engineering team to fuse any last technical requirements with visual design.
			</p>
			<p class="p2">
			There were a number of things that changed from my original mockups. For example, the way the code was set up meant that my original idea of infinity scroll was infeasible so we switched to a chunked, paginated approach. The reason for this lay in the inability to save information dynamically without a button to indicate a way to save and move to another stage in the process.
			</p>
			<p class="p2">
			In addition, the original time-selection designs were altered to provide an even clearer, more transparent approach to pharmacy availability for services. I explored a number of ways to present the date/time based on a number of clinical service platforms and ultimately went with an option to neatly fuse location, date, and time together. After internal testing and mentor feedback, it became clear that toggling quickly between different locations if your preferred date/time was unavailable at one was a valuable feature.
			</p>

			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/calendar_iterations.png')} alt=""/>
			<div class="break3"></div>

			<p class="p2">
			The result was a multi-page, accordion-based flow that allowed for information to be hidden and shown neatly. The design was heavily based off of Material Design (Material UI) given that it was the direction the engineering team was veering towards for inputs and system design.
			</p>

			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/new_flow.png')} alt=""/>
			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/intake_gif.gif')} alt=""/>
			<div class="break3"></div>

			{/* Impact and Conclusion */}
			<p class="p5">Impact of Redesign</p>
			<h2>Enabling Access to Care</h2>

			<p class="p2">
				Quantitatively, the redesigned flow has nearly half of the pages of the previous one. It has gone from a 1-question-per-page format to a neatly organized and highly usable form. Time has been reduced from an average of 5-8 minutes to an average of 2.75 minutes.
			</p>
			<p class="p2">
				Qualitatively, impact is measured by our user feedback. Most importantly, in line with our ethos for accessible and inclusive care, the patient intake redesign was done with seniors and less tech-savvy individuals in mind. I've received feedback from actual end users stating the ease and lack of difficulty they encountered while signing up for an appointment. Beyond just numbers, this is what the purpose of the redesign was.
			</p>
			<p class="p2">
				As of 2021, my current intake redesign has supported over 7 million unique patients as they signed up to receive their COVID-19 vaccines across Canada.
			</p>
			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/user_feedback.png')} alt=""/>
			{/* {/* <div class="break3"></div> */}
			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Intake/devices.png')} alt=""/>
			<div class="break3"></div>

		</div>
	</div>
);
};

export default MedMePatient;
