import React from 'react';


const LoopioImport = () => {
return (
	<div class="col-12">
		<h1>Loopio: Enabling a More Seamless Migration of Library Content Upfront</h1>
		<img class="img-responsive" src={require('../assets/work/Loopio_LWSI/placeholder1.png')} alt=""/>
		<div class="break4"></div>
		<p class="p1">
		Large, enterprise clients often have complex styling needs for their proposals. Loopio’s Enablement & Support teams (onboarding consultants) can spend months setting up styled content in customers’ libraries during the onboarding process. Beyond this, manually styling content post-content-import can take up to 80% of our onboarding consultants' allocated hours, delaying how soon customers can start working within the platform.</p>
		<p class="p1">
		This work is a streamlined, simplified, and facelifted version of an existing tool. The usage of the tool had been paused because something went wrong with one of our customers and it took 50+ free hours to rectify. As we shift towards an Enterprise-first clientele and billable hour model for our onboarding teams, it becomes paramount we find a scalable solution to tackle (1) import problems before they occur and (2) save our consultants' time for higher value tasks.
		</p>
		<p class="p1">
		I led an internal project to enable co-design across multiple departments, leaning into our annual OKR on reducing onboarding efforts for a user-friendly service solution.
		</p>
		<div class="break3"></div>

		{/* At a Glance Section */}
		<hr/>
		<h2>At a Glance</h2>
		<p class="p2">
			<strong>Date:</strong> Q2 2022
		</p>
		<p class="p2">
			<strong>Role:</strong> Lead Product Designer
		</p>
		<p class="p2">
			<strong>Skills:</strong> Stakeholder interviews, workshop facilitation, UI/UX design, service design
		</p>
		<p class="p2">
			<strong>Artifacts:</strong> User flows, service blueprint, personas & JTBD, discussion guides, collaborative workshops, Figma designs & prototypes, user stories
		</p>
		<div class="break4"></div>
		<hr/>

		<div class="break4"></div>

		{/* Background & Opportunity */}
        <p class="p5">The why & the how</p>
		<h2>
        The existing solution was no longer offered because it was more faulty than viable. But we needed to map things out to understand why a complex system was implemented to tackle complex needs. How could we simplify both?
        </h2>
		<p class="p2">
        At the time, my team's tech lead was one of the original engineers who had built the original solution. The best way to describe it was that it was a highly tedious process where members of Customer Support and Enablement Services needed to manually import documents, inspect elements, grab source code, and run multiple backend scripts to populate a user's platform with non-visible styles.
		</p>
		<p class="p2">
        However, the reason this was built, was to (ideally) streamline the storage and export process of styled documentation. Enterprise customers typically come with strict styleguides on how their exported material should look like. Loopio had a system to map things out when they left the platform. However, the tool was initially created so content could be brought into the library already styled; customers would otherwise import plain (unstyled) text, which would stay in their libraries as such, only to export and re-style them in their Word Documents.
		</p>

		<div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_LWSI/simplify_start.png')} alt=""/>
        <div class="break4"></div>
		<p class="p2">
        I led a series of interviews with multiple internal stakeholders. From here, we were able to map out a service blueprint that illustrated the roles of different touchpoints, actors, and painpoints. This was paramount in identifying where the most time was sunk and prioritizing work to be changed in our redesign.
		</p>

		<div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_LWSI/users_flows.png')} alt=""/>
        {/* <div class="break4"></div> */}
        <div class="break3"></div>

        {/* Users */}
        <p class="p5">Defining the MVP</p>
        <h2>
        Consolidating themes into an actionable step forward
        </h2>
		<p class="p2">
        Pain existed in multiple areas of the process:
		<ul>
			<li>
				There was <strong>no centralized way </strong>for our internal teams to see who had this feature turned on. We generally track features for customers on an internal dashboard, but this was kept in a hidden Excel sheet.
			</li>
			<li>
				Customer support was <strong>manually importing, inspecting, copying source code, and running backend scripts</strong> without a visual interface to see the impact of their work.
			</li>
		</ul>
		</p>
		<p class="p2"> Things were rough for our customers too:
		<ul>
			<li>
				Customers who had this styled import feature turned on also <strong>had no idea</strong>. There was nothing to indicate it in their own Loopio platforms.
			</li>
			<li>
				In line with the lack of indication, customers also had <strong>no system feedback </strong>if anything went wrong. Styles not imported? Styles imported properly? No feedback either way.
			</li>
		</ul>
		</p>
		<p class="p2">
				Another consideration was about backwards compatibility - the solution we built needed to be compatible with the version that our customers currently had.
		</p>

		<p class="p2">
			With some "creative ideation sketching" (exploring visual/interaction and systemic options) from my end, my PM and I collaboratively identified the cornerstones of a solid MVP.
		</p>

		<div class="break4"></div>
		<img class="img-responsive" src={require('../assets/work/Loopio_LWSI/sketches1.png')} alt=""/>
		<div class="break4"></div>
		<img class="img-responsive" src={require('../assets/work/Loopio_LWSI/MVP.png')} alt=""/>
        <div class="break3"></div>


        {/* <div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_Review/users.png')} alt=""/>
        <div class="break3"></div> */}

        {/* <p class="p2">
        Trust, which this project fell under, referred to the maintenance period and structure after building a library. In order for content to be usable in projects (via automated entry population or manual usage), content had to be reliable and trustworthy; to be this meant customers needed a manageable way to review, audit, and edit existing entries.
        </p>
        <p class="p2">
        Review cycles, as they are currently set up, assume that SME (Subject Matter Experts) will review entries at a regular cadence. However, our metrics indicated that reviews were being left undone, with a lower performance level for our Enterprise customers. 
        </p> */}

        {/* Early research */}
        {/* <p class="p5">Kicking off Discovery</p>
        <h2>
        Using existing insights to drive early collaborative brainstorming and cross-department alignment
        </h2>
        <p class="p2">
        Despite knowing issues with library management, we didn't know exactly what was happening. Our goal was to uncover opportunities from customer interviews, and use those to shape the final project outcome.

        <ol>
            <li>
                I generated a preliminary report from our cross-department user feedback collection tool. I gleaned 9 primary themes around library management, using them to form a prelimary discussion guide.
            </li>
            <li>
                My PM and I ran a collaborative brainstorming session with the developers, to understand different perceptions on why reviews were left uncompleted, what success metrics could look like, and what risks/limitations to be aware of.
            </li>
        </ol>
        </p> */}

        {/* Early research */}
        {/* <p class="p5">Defining Success</p>
        <h2>
        Okay, but what do success and impact look like?
        </h2>
        <p class="p2">
        Our goal was: to enable our customers and Professional Services team to more seamlessly migrate library content for ENT customer by <strong>reducing the effort spent post content import</strong> to manually style each library entry appropriately.
        </p> */}


    {/* Round 1 */}
        <p class="p5">Co-creating with Stakeholders</p>
        <h2>
        Translating a MVP into a communication artifact to establish alignment with stakeholders and development teams
        </h2>
        <p class="p2">
		I pieced together internal (Loopio employee) and external (enterprise customers)-facing flows. I identified what was to change, followed with visuals, and how it would change the role of certain actors.
        </p>

        <div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_LWSI/newflow.png')} alt=""/>
        <div class="break4"></div>

        <p class="p2">
        I sought feedback from my internal stakeholders in the way of qualitative feedback. What gaps did I miss? What features were non-negotiable? Did this properly solve their current painpoints? They would be the users of this work, so this validation was crucial to ensure the work was on track.
        </p>

		<div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_LWSI/feedback1.png')} alt=""/>
        <div class="break4"></div>

		<p class="p2">
        I sought feedback from developers in the way of a What-If workshop. I like to run these to ensure I consider edge cases and error states, and the developers always ask incredible questions that add value to my design practice.
        </p>

		<div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_LWSI/whatif.png')} alt=""/>
        <div class="break4"></div>


        <p class="p2">
        At the end of the feedback sessions, it became clear that there were technical considerations I hadn't properly considered. One of the primary concerns, especially from the onboarding team, revolved around error-proofing the import process, and flagging content that couldn't be imported with style.
        <ul>
            <li>
			This concern was not unfounded. One of our biggest customers had an unidentified hiccup during import, and our team spent over 50 free hours to clean up tens of thousands of entries.
            </li>
        </ul>
        </p>
        {/* <p class="p2">
            In the grand scheme of impact however, library review management was the 7th-most popular idea, with 179 requesting accounts, yielding a potential $6.6 million ARR impact.
        </p> */}

        <div class="break3"></div>
		{/* <div class="img-placeholder"></div>
        <div class="break3"></div> */}

        {/* Copy */}
        <p class="p5">Technical Tradeoffs</p>
        <h2>
            Sticking to our guns to ensure little-to-no scope creep
        </h2>
        <p class="p2">
        When my PM returned, we revisited our scope and collaboratively agreed what we should cut based on concerns raised during his vacation.
        </p>

		<div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_LWSI/modal.png')} alt=""/>
        <div class="break4"></div>
       
        <p class="p2">
        Our import process is a one and done deal. You cannot save sections to import, you can't have a draft session to save progress. Unfortunately, this means that if your styles have not been set up properly or you have a new style, you cannot add it on the spot. It will be imported unstyled.
        </p>

		<p class="p2">
        However, we do flag what style names are missing, to ensure you can reach out to Loopio Support with as much information as possible.
        </p>
		
		<div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_LWSI/phase2.png')} alt=""/>
        <div class="break4"></div>

        <p class="p2">
        In the case content is added without styles, it would be ideal to find a way to search for it. What entries need to be modified later? I explored a series of sketches in the hopes of a phase 2, but these were deemed technically heavy and pass over in favour for a lean MVP and another project.
        </p>

        <div class="break3"></div>


           {/* Round 2 */}
           <p class="p5">Putting it all together</p>
        <h2>
        	Reducing library onboarding efforts for our internal folks
        </h2>

    


        <div class="break4"></div>
        <img class="img-responsive" src={require('../assets/work/Loopio_LWSI/final_flow.png')} alt=""/>
        <div class="break4"></div>
        
        <div class="break3"></div>

        {/* Copy */}
        <p class="p5"> Measuring Impact</p>
        <h2>
            Did we achieve what we set out to do?
        </h2>
		<p class="p2">
			As Loopio stood today, the amount of post import styling work can take up to 80% of a migration project for the professional services team, which delays time for customers to start projects
		</p>
		
		<p class="p2">
        Our goal was: to enable our customers and Professional Services team to more seamlessly migrate library content for ENT customer by <strong>reducing the effort spent post content import</strong> to manually style each library entry appropriately.
        </p>

		<p class="p2">
        This work levelled directly into one of our 2022 OKRs about streamlining onboarding and improving the experience for our Enterprise customers.
        </p>

		<p class="p2">
        I will be candid. Our primary metric was time-savings, but as the economy took a bit of a tumble, I'm not sure where we are with procuring new Enterprise customers. As such, since we launched this feature in late 2022, I don't have a lot to confirm whether or not we've hit our targeted reduction.
        </p>

        <div class="break3"></div>

        {/* End */}
        <p class="p5"> In Conclusion</p>
        <h2>
            Thoughts & Reflection
        </h2>
        <p class="p2">
			I think back to the process of doing this work and remember how I struggled with technical conversations at the start. I also struggled with scope and decision-making, and left more of that in the hands of my PM. But walking out of this project, I really loved being able to apply a more service design approach to my work at a tech company.
        </p>

		<p class="p2">
			I had, in 2020, wanted to work in service design and consulting, before returning to product design. 
        </p>

		<p class="p2">
			As such, I really enjoyed weaving more human touchpoints into my digital research and work. I'm optimistic there will be more opportunities for this in the future, both here and in the tech industry!
        </p>

		<p class="p2">
			Perhaps the biggest drawback during this project was realizing the limited bandwidth and technical flexibility of our work. I'm aspirational. I would've loved for a phase 2 of the work. But realistically, we moved onto our next project and those designs may not see the day until a large-enough problem has been found. I hope that instead of that, there will be enough resources to prioritize internal (onboarding) tools in the future this way.
        </p>


	</div>
);
};

export default LoopioImport;
