import React from 'react';

const QuantifiedSelf = () => {
return (
	<div class="parent-container">
		<div class="col-12">
			<h1>The Quantified Self: 2018 Data Visualizations</h1>
			{/* <div class="img-placeholder"></div> */}
			{/* <img class="img-responsive" src={require('../assets/work/MedMe_Figma/placeholder.png')} alt=""/> */}
			{/* <div class="break4"></div> */}
			<p class="p1">
            At the end of my engineering degree, I wanted to investigate the idea of quantifying myself by recording and analyzing my habits. I don't think a person can be broken down into mere numbers (there are more nuances than that), but I wanted to challenge my understanding of humanity either way. This is a personal project of mine, as well as a technical exercise to build up my data visualization and data analysis skills.
			</p>
			<div class="break5"></div>

			{/* At a Glance Section */}
			{/* <hr/>
			<h2>At a Glance</h2>
			<p class="p2">
				<strong>Date:</strong> Jan - May 2021
			</p>
			<p class="p2">
				<strong>Role:</strong> Solo Product Designer
			</p>
			<p class="p2">
				<strong>Skills:</strong> Design audit, systems design, cross-team collaboration
			</p>
			<p class="p2">
				<strong>Artifacts:</strong> Design audit documentation, component library (Figma), design guidelines
			</p>
			<div class="break4"></div>
			<hr/> */}

			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/play/2018/jan1.jpg')} alt=""></img>
			<div class="break3"></div>

			{/* Background & Opportunity */}
			<p class="p5">The Premise</p>
			<h2>What makes a person? Is it their habits? Is it their consumption? Are humans just a conglomerate collection of all the numbers they output, in whatever way or form?</h2>

			<p class="p2">
			For 2018, I collected and tracked my own habits to create and analyze my own data. Each month focused on a different theme or habit, as well as different methods to collect data.
            </p>
            <p class="p2">
            The goal was to collect and visualize data for the whole year—I collected data for 12 months but failed to analyze past January - April. In an ideal world, I'd like to redo this project and see it through, but I highly doubt I will have time to go back and finish the analysis from May - December.
            </p>

			<div class="break3"></div>
            <img class="img-responsive" src={require('../assets/play/2018/overview.png')} alt=""></img>
            <div class="break4"></div>

            <hr></hr>
            <div class="break4"></div>

            {/* January */}
			<p class="p5">January</p>
			<h2>A month of caffeinated beverages</h2>
        </div>
        <div class="row">
            <div class="col-6">
                <img class="img-responsive" src={require('../assets/play/2018/jan_week.png')} alt=""></img> 
                <div class="break4"></div>
            </div>
            <div class="col-6">
                <img class="img-responsive" src={require('../assets/play/2018/jan_breakdown.png')} alt=""></img> 
                <div class="break4"></div>
            </div>
        </div>
        <div class="col-12">
			<p class="p2">
			I started with something small. As tame as it may sound, prior to 2018, I barely drank caffeinated beverages. I had spent my winter 2017 in Singapore, where I began to drink daily coffees and I wanted to see if I could continue this habit into January as well.
            </p>
            <p class="p2">
            I tracked my drinks in an Excel spreadsheet and translated my findings over to Sketch when visualizing them. At the time, Sketch was my major tool for all things visualization/interface design, and this month focused heavily on just reporting frequency and volume.
            </p>

            <div class="break4"></div>
            <hr></hr>
            <div class="break4"></div>

            {/* February */}
			<p class="p5">February</p>
			<h2>A month of food groups</h2>

			<p class="p2">
			In February, I tracked my eating habits. The visualizations illustrate the food groups (plus caffeine) that I consumed. I annotated the foods I ate for the month in an Excel sheet, and cleaned the data accordingly to derive sets of analyses from it. I chose to focus on varieties, not quantity of food eaten (knowing myself, that could have led to unhealthy dietary restriction).
            </p>
            <p class="p2">
            Seeing as February was a short month, I wanted to do a primary monthly visualization to see a distribution of food groups like a reverse bar graph. I also wanted to have a format without a vertical restriction due to the varied types of foods I would be eating throughout the month.
            </p>

            <div class="break4"></div>
            <img class="img-responsive" src={require('../assets/play/2018/feb_foodgroups.png')} alt=""></img> 
            <div class="break4"></div>
            <p class="p2">
            I tracked smaller things as well, such as my vitamin/mineral intake and my most commonly indulged-in snacks. I was able to see how spotty I was in my vitamin intake plus how my snack of choice peaked with cookies near the end.
            </p>
            <div class="break4"></div>
        </div>
        <div class="row">
            <div class="col-6">
                <img class="img-responsive" src={require('../assets/play/2018/feb_vitamins.png')} alt=""></img> 
                <div class="break4"></div>
            </div>
            <div class="col-6">
                <img class="img-responsive" src={require('../assets/play/2018/feb_larabar_cookie.png')} alt=""></img> 
                <div class="break4"></div>
            </div>
        </div>
        <div class="col-12">
            <div class="break4"></div>
            <p class="p2">
            Around here, I started learning about rudimentary data science/analysis in my coursework and was very excited to implement some learnings into my personal project. As a result, I turned to R to start clustering trends in my self-collected data to see if I had any unconscious eating trends.
            </p>

            <div class="break4"></div>
            <img class="img-responsive" src={require('../assets/play/2018/feb_cluster.png')} alt=""></img> 
            <div class="break4"></div>
            <hr></hr>
            <div class="break4"></div>

            {/* March */}
			<p class="p5">March</p>
			<h2>Dissecting My Personal Colour Palette</h2>
            <p class="p2">
                For March, I tracked the outfits I wore. I took an "Outfit of the Day" photo every day. From here, I quantified and analyzed them according to article of clothing, brands worn, and tried to extrapolate trends from my daily aesthetic.
            </p>
            <p class="p2">
                In seeing the colour swatches, I can say that I wore a lot of black. There was only one day where I didn't wear black. In seeing this graphic, I also question some of my colour combinations.
            </p>
            <div class="break4"></div>
            <img class="img-responsive" src={require('../assets/play/2018/march_col.png')} alt=""></img> 
            <div class="break4"></div>
            <p class="p2">
            Do I tend to shop at the same places? Yes. Muji and Uniqlo were by far the most commonly worn brands during this month and I wasn't very surprised at seeing that. The previous few months were spent in Singapore where I added mostly those two brands to my closet.
            </p>
            <div class="break4"></div>
            <img class="img-responsive" src={require('../assets/play/2018/march_weekly_brands.png')} alt=""></img> 
            <div class="break4"></div>
            <p class="p2">
            I continued with clustering my data and found new and creative ways to analyze my clothing choices. How similar were my outfits? Were there specific outfits/choice where I chose not to wear a belt?
            </p>
            <div class="break4"></div>
        </div>
        <div class="row">
            <div class="col-6">
                <img class="img-responsive" src={require('../assets/play/2018/march_black.png')} alt=""></img> 
                <div class="break4"></div>
            </div>
            <div class="col-6">
                <img class="img-responsive" src={require('../assets/play/2018/feb_larabar_cookie.png')} alt=""></img> 
                <div class="break4"></div>
            </div>
        </div>
        <div class="col-12">
            <hr></hr>
            <div class="break4"></div>

            {/* April */}
			<p class="p5">April</p>
			<h2>Interacting with Who?</h2>
            <p class="p2">
            April was a month of interactions. I looked towards the people I spoke to; my cue for this month was based on verbal communication after speaking to a friend, and him suggesting that I focus on something that "speaks about how I interact with others". It was a refreshing twist to record these because my first three months were solely about my own habits.
            </p>
            <p class="p2">
            I was browsing either Pinterest or Dribbble for data visualization inspiration and I came across a visualization editorial spread what illustrated categories and frequencies across bars and rows. I wanted to model my primary monthly visualization after it and was able to divide the rows neatly given the 30 days in the month.
            </p>
            {/* <div class="img-placeholder"></div> */}
            <p class="p2">
            Each separated horizontal bar represents a day in the month, and each bar has been calibrated to reflect the number of people I recorded talking to that day. I noted down the unique people I spoke to via Excel and Notes (on my phone).
            </p>
            <div class="break4"></div>
            <img class="img-responsive" src={require('../assets/play/2018/april_relationships.png')} alt=""></img> 
            <div class="break4"></div>
            <p class="p2">
            I went on a small grad trip at the end of the month, hence the spike in talking to strangers.
            </p>
            <p class="p2">
            I also recorded the number of unique verbal conversations/day within that month. There was one day where I barely spoke to anyone (locked in my room to study), but there were also days where I engaged with over 25 people in one day.
            </p>
            <div class="break4"></div>
            <img class="img-responsive" src={require('../assets/play/2018/april_frequency.png')} alt=""></img> 
            <div class="break4"></div>
            <hr/>
            <div class="break4"></div>
             {/* Conclusions */}
			<p class="p5">Reflection</p>
			<h2>What did I learn? What would I do differently?</h2>
            <p class="p2">
                This is by far one of my favourite personal projects because I was able to direct and oversee the entire process. It was a heavy learning-based project as well, and the requirements were entirely based on my learning goals and self gratification.
            </p>
            <p class="p2">
            I do rue how I chose not to continue it after 4 months. I collected data for months after, such as:
            <ul>
                <li>
                    Tracking messages from/to friends (Facebook Messenger, WhatsApp, etc)
                </li>
                <li>
                    Duration of showers
                </li>
                <li>
                    Sleep quality vs. sleeping aids used
                </li>
            </ul>
            </p>
            <p class="p2">
            Unfortunately, I also learned that it took a bit of a toll on my life to constantly quantify myself. While it taught me that people cannot be quantified by mere inputs and outputs, I struggled with continuing the project because I felt I was trying to put myself in a box too much. I am not a test subject meant to be quantified and tracked every day.
            </p>
            <p class="p2">
            The project was incredibly educational though. I learned to be more creative and intentional in the way I presented my data and findings, and this has carried over because I became used to asking "why" repeatedly. What was the purpose of tracking these habits? What visualization did I want to practice and why? And when extrapolating trends, why did I eat/do/dress a certain way at the time?
            </p>
            <p class="p2">
            From a technical standpoint, I had a lot of fun fusing scoping, design, and analysis together. I truly enjoyed being able to use coursework in my daily life and it was great fun to create my own datasets to plop into R.
            </p>
            <p class="p2">
            That being said, some of my visualizations simply don't make the most sense. I translated things directly from R and ... they don't necessarily translate into the most meaningful visualizations. That is something that looking back, I would love to redo or be more thoughtful in doing—my frequency and volume visualizations I have less to speak to, but my clusters and trends could be greatly improved.
            </p>
		</div>
	</div>
);
};

export default QuantifiedSelf;
