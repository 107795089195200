import React from 'react';

const LoopioAccessibility = () => {
return (
	// <div class="parent-container">
	<div>
		<div class="col-12">
	<h1>Driving an Accessible Component Library (and more)</h1>
			{/* <div class="img-placeholder"></div> */}
			<img class="img-responsive" src={require('../assets/work/Loopio_a11y/placeholder.png')} alt=""/>
			<div class="break4"></div>
			<p class="p1">
				This work is two-fold:
				<ol>
					<li>When I first joined Loopio in 2021, we were looking at <strong>standardizing our component library (Loopui)</strong> and I hit the ground running in starting an audit, contributing to improvement plans, standardizing changes, and providing continuity to the work as it changed hands between Design Leads.</li>
					<br/>
					<li>I became interested in <strong>accessibility</strong> and was given the means to learn about it, and decided to become the Accessibility SME for the Design & Research team. This resulted in a mixture of contributing back into the work done for Loopui and spearheading intentional learning opportunities.</li>
				</ol>
			</p>
			<p class="p1">
				I couldn't have done this alone. I thank Level Access for teaching us about accessibility, my Design Team for learning with me, my developers for their patience and willingness to jump into an unknown territory, and my external mentors and a11y community for all the mentorship and teachings.
			</p>
			<div class="break4"></div>

			{/* At a Glance Section */}
			<hr/>
			<h2>At a Glance</h2>
			<p class="p2">
				<strong>Date:</strong> 2021-2023
			</p>
			<p class="p2">
				<strong>Role:</strong> I was one of the many villagers it took to bring this work alive.
			</p>
			<p class="p2">
				<strong>Skills:</strong> Design audit, systems design, cross-team collaboration, accessibility annotations, toolkit creation
			</p>
			<p class="p2">
				<strong>Artifacts:</strong> Design audit documentation, annotated component library (Figma), co-learning workshops, developer handoff material
			</p>
			<div class="break4"></div>
			<hr/>

			<div class="break3"></div>

			{/* Background & Opportunity */}
			<p class="p5">The state of our "design system"</p>
			<h2>There was no source of truth for Loopio design and development components.</h2>

			<p class="p2">
				There was a lot going on on the Loopio web platform. There was a lack of cohesion and consistency across multiple pages/functional areas of the platform (e.g. some corners were still styled with 2010 Bootstrap elements). We also saw lack of parity between Figma components and actual deployed components; there was no singular source of truth anyone could turn to.
			</p>
			<p class="p2">
				In addition, we had a v.1.0 of a component library (Figma) that was severely outdated, while there were efforts to roll out a v.2.0 without a proper education or integration plan in place.
			</p>
			<p class="p2">
				I say we had a component library because that was all we had. We did not have a design system with cohesive content guidelines or principles; we had disparate libraries on Storybook and Figma that were not maintained well.
			</p>

			<div class="break3"></div>
			{/* <div class="img-placeholder"></div> */}

			{/* Starting the process */}
			<p class="p5">Starting an Audit</p>
			<h2>Let's begin by auditing the live platform to understand what component (groups) exist and are used.</h2>

			<p class="p2">
			I began by doing research on how to start an audit, going wild with screencaptures; my work was messy and involved me drawing all sorts of arrows and flows to map out interactions and capture modals and less-visited corners of the platform.
			</p>
			<div class="break4"></div>
			{/* <img class="img-responsive" src={require('../assets/work/Loopio_a11y/audit.png')} alt=""></img>
			<div class="break4"></div> */}
			<img class="img-responsive" src={require('../assets/work/Loopio_a11y/audit1.png')} alt=""></img>
			<div class="break4"></div>

			<p class="p2">
			Interaction patterns were a little trickier to capture, and I hand-coded a local site to showcase a video repository of clustered patterns. 
			</p>
			<div class="break4"></div>
			<img class="img-responsive" src={require('../assets/work/Loopio_a11y/audit2.png')} alt=""></img>
			<div class="break4"></div>
			<p class="p2">
			I was working closely with a Design Lead, who took my audit findings and synthesized them into a series of documents and discussions with dedicated front-end developers. Together, they built a contribution model, slack channel, and a series of design principles that we rolled with.
			</p>
			<div class="break3"></div>

			{/* Research */}
			<p class="p5">Not just for designers</p>
			<h2>Auditing sentiments around the current component library</h2>

			<p class="p2">
			Beyond a component audit, I led internal research initiatives to benchmark developer and designer sentiments around our existing component library. Not only did this serve to highlight existing painpoints as we attempted to roll out (and eventually scaled back) a v.2.0 library, but I reached out to industry experts to understand external progress and tools in maintaining, contributing to, and structuring a(n eventual) design system.
			</p>

			<p class="p2">
			It was clear that the design side was sorely lacking. Developer sentiment was much higher, although there were gaps as well.
			</p>

			<div class="break4"></div>
			<img class="img-responsive" src={require('../assets/work/Loopio_a11y/sentiments.png')} alt=""></img>
			<div class="break3"></div>

			{/* Converging */}
			<p class="p5">Baking in Accessibility</p>
			<h2>How might we approach our work with an accessibility lens?</h2>

			<p class="p2">
			Level Access came to Loopio in early 2022 for a series of hands-on accessibility training sessions. With my fellow designers, some engineers, and Product, we spent 2 weeks learning about annotations, assistive technology testing tools, and ways to write semantic HTML.
			</p>

			<p class="p2">
			Beyond feature work, accessibility was an important initiative as large, government and/or enterprise customers were requiring a level of compliance for contracts to go through.
			</p>

			<div class="break3"></div>
		</div>

		<div class="row">
			<div class="col-6">
				<img class="img-responsive" src={require('../assets/work/Loopio_a11y/annotation1.png')} alt=""></img>
			</div>
			<div class="col-6">
				<h2>Starting with Annotations</h2>
				<p class="p2">
					I started with design annotations, and thinking through them forced me to think about tab order and interactions of elements - this would eventually influence my UI work (e.g. no longer consider less accessible options), as things stopped making sense outside of a sighted user perspective.
				</p>
			</div>
		</div>
		<div class="break3"></div>
		<div class="row">
			<div class="col-6">
				<img class="img-responsive" src={require('../assets/work/Loopio_a11y/annotation2.png')} alt=""></img>
			</div>
			<div class="col-6">
				<h2>Enhancing the Component Library</h2>
				<p class="p2">
				I pored through WCAG guidelines and annotated our Figma component library. Irrespective of actual implementation, these annotations would serve as a source of truth for how our components work. For the development team working on rewriting our components (they were keeping the visuals but translating the code into a new stack), these would serve as guidelines for their work.
				</p>
			</div>
		</div>
		<div class="break3"></div>
		<div class="col-12">
			{/* Workshops */}
			<p class="p5">Intentional Learning Opportunities</p>
			<h2>Rolling out such a large initiative is a team sport and I was doing a disservice by being a perfectionist</h2>

			<p class="p2">
			Until the team expanded to include another accessibility-focused designer, I was the solo member invested in the initiative. I monopolized a series of weekly meetings to lead a series of annotation workshops and open discussion for accessibility-oriented questions.
			</p>
			<div class="break4"></div>
			<img class="img-responsive" src={require('../assets/work/Loopio_a11y/learning3.png')} alt=""></img>
			<div class="break4"></div>
			<p class="p2">
			These workshops were geared towards
			<ol>
				<li>Understanding accessibility basics for component interactions (which would be ultimately handled by the development team and I)</li>
				<li>Understanding accessibility considerations for workflows</li>
			</ol>
			</p>
			<p class="p2">
			I championed cross-functional communication of our mission as well, by presenting progress and outcomes of our work at company-wide meetings. These were attended by the entire company and I routinely received positive feedback from leadership and the CTO.
			</p>

			<div class="break4"></div>
			<img class="img-responsive" src={require('../assets/work/Loopio_a11y/smash.png')} alt=""></img>
			<div class="break3"></div>

			{/* Closing */}
			<p class="p5">Current state of accessibility at Loopio</p>
			<h2>Contributions and future direction</h2>

			<p class="p2">
				Accessibility at Loopio is not complete, and the knowledge only stands to grow as it becomes more rooted in fundamental things like our component library and design flows. As that grows and becomes a fully-fledged design system, my hope is for it to grow organically to become second-nature to the team.
			</p>
			<p class="p2">
				This work is very designer-first, developer-second. I'd love for research to be included as well, through inclusive recruitment and testing protocol. I'd also love for Product to be more involved and invested in pushing accessibility fixes, instead of bucketing it into technical debt.
			</p>

			<div class="break4"></div>
			<img class="img-responsive" src={require('../assets/work/Loopio_a11y/annotation3.png')} alt=""></img>
			<div class="break4"></div>


			<p class="p2">
				Beyond annotating the primary Figma file to communicate ideal end-state behaviour of components, I've spearheaded:
				<ol>
					<li>The creation of Loopio's own <strong>annotation toolkit</strong></li>
					<li>A <strong>series of workshops</strong> to teach fellow designers how to annotate designs</li>
					<li>Streams of <strong>communication with developers</strong>, where they've created their own accessibility development protocol</li>
					<li>Beginner <strong>UAT practices with assistive technology</strong>when finalizing feature launches </li>
				</ol>
			</p>
			<div class="break4"></div>
			<img class="img-responsive" src={require('../assets/work/Loopio_a11y/audit3.png')} alt=""></img>
			<div class="break4"></div>
		</div>
	</div>
);
};

export default LoopioAccessibility;
