import React from 'react';

const WearableMedia = () => {
return (
	<div class="parent-container">
		<div class="col-12">
			<h1>Wearable Media: Dualities</h1>
			{/* <div class="img-placeholder"></div> */}
			<img class="img-responsive" src={require('../assets/work/MedMe_Figma/placeholder.png')} alt=""/>
			<div class="break4"></div>
			<p class="p1">
				A few months after they launched a product, I was hired as the first designer at MedMe Health. This meant that there were little to no design practices in place, including the noticeable absence of standardized design assets. As a result, one of my goals was to document, create, and deploy 2 styleguides to reflect both our patient and pharmacist-facing portals.
			</p>
			<p class="p1">
				This design library is a living, breathing collection of assets. It is far from finalized. As the product continues to evolve and the team continues to grow, this library will change as well.
			</p>
			<div class="break4"></div>

			{/* At a Glance Section */}
			<hr/>
			<h2>At a Glance</h2>
			<p class="p2">
				<strong>Date:</strong> Jan - May 2021
			</p>
			<p class="p2">
				<strong>Role:</strong> Solo Product Designer
			</p>
			<p class="p2">
				<strong>Skills:</strong> Design audit, systems design, cross-team collaboration
			</p>
			<p class="p2">
				<strong>Artifacts:</strong> Design audit documentation, component library (Figma), design guidelines
			</p>
			<div class="break4"></div>
			<hr/>

			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Figma/styleguidegif2.gif')} alt=""></img>
			<div class="break3"></div>

			{/* Background & Opportunity */}
			<p class="p5">THE PROBLEM</p>
			<h2>Design debt, design inconsistencies, and poorly managed time lead to serious scalability issues for digital solutions. This is where standarized component libraries work their magic.</h2>

			<p class="p2">
			Standardizing assets was not only an incredibly large task (solo designer, rapidly growing startup), but was further complicated by a number of moving parts:
			<ol>
				<li>
					A month in, I pitched, designed, and managed a patient intake redesign that took meant changing and redefining assets on our patient-facing platform.
				</li>
				<li>
					A few months later, we changed our toolkit. Despite having experience with Figma, I had never been in charge of building an entire component library (let alone two!) by myself.
				</li>
				<li>
					My time at the company was marked by the urgency and panic of supporting the COVID-19 vaccine rollout across Canada, meaning building a style guide was not a priority.
				</li>
			</ol>
			</p>

			<div class="break3"></div>
			{/* <div class="img-placeholder"></div> */}

			{/* Starting the process */}
			<p class="p5">THE Start</p>
			<h2>Auditing existing platform features and our existing design setup</h2>

			<p class="p2">
				I started by conducting a visual and usability audit of the platform. This was done for both patient and pharmacist-facing sides.
			</p>

			<div class="break3"></div>
			{/* <div class="img-placeholder"></div> */}
			<img class="img-responsive" src={require('../assets/work/MedMe_Figma/confluence.png')} alt=""></img>

			{/* Background & Opportunity */}
			{/* <p class="p4">THE PROBLEM</p>
			<h2>Design debt, design inconsistencies, and poorly managed time lead to serious scalability issues for digital solutions. This is where standarized component libraries work their magic.</h2> */}
			<div class="break3"></div>
			<p class="p2">
			I also requested files of our existing mockups (Adobe XD). As suspected, there was no substantial system and everything had been segmented into very discrete, disconnected files.
			</p>

			<div class="break3"></div>
			{/* <div class="img-placeholder"></div> */}
			<img class="img-responsive" src={require('../assets/work/MedMe_Figma/old_mocks.png')} alt=""></img>
			<div class="break3"></div>

			{/* Research */}
			<p class="p5">Research</p>
			<h2>Studying industry standards and best practice</h2>

			<p class="p2">
				There were a number of existing design systems that I referenced, such as Polaris (Shopify), Mercury (Canada Post), Human Interface Guidelines (Apple), Material Design (Google), and Material UI (React).
			</p>
			<p class="p2">
				MedMe’s technical team had a certain inclination towards Material UI meaning that many of the redesigned patient-facing elements did borrow visual elements from it.
			</p>
			<p class="p2">
				In addition, I requested my team to switch over from Adobe XD to Figma for design and prototyping purposes. After looking into differences between the two tools, I felt that certain features allowed for more collaborative processes, better organization, and allowed for a more seamless hand-off process with the engineers.
			</p>

			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Figma/references.png')} alt=""></img>
			<div class="break3"></div>

			{/* Converging */}
			<p class="p5">Consolidating</p>
			<h2>Atomic Design, MedMe Style</h2>

			<p class="p2">
			Following Brad Frost’s Atomic Design approach, I constructed a combination of Atoms, Molecules, Organisms, Templates, and Pages.
			</p>

			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Figma/pt_forms.png')} alt=""></img>
			<div class="break3"></div>
		</div>

		<div class="row">
			<div class="col-6">
				<img class="img-responsive" src={require('../assets/work/MedMe_Figma/structure.png')} alt=""></img>
			</div>
			<div class="col-6">
				{/* Architecture */}
				<h2>System Structure</h2>
				<p class="p2">
					I further organized them according to the categorization/use cases of our patient-facing and pharmacist-facing platforms.
				</p>
				<p class="p2">
					For example, MedMe Health white labels their solutions for clients meaning certain elements were branded for our enterprise clients.
				</p>
				<p class="p2">
					However, their pharmacist-facing portal uses entirely different elements that are more reminiscent of the original design before I joined the team.
				</p>
			</div>
		</div>
		<div class="break3"></div>
		<div class="row">
			<div class="col-6">
				<img class="img-responsive" src={require('../assets/work/MedMe_Figma/typography.png')} alt=""></img>
			</div>
			<div class="col-6">
				{/* Typography */}
				<h2>Typography</h2>
				<p class="p2">
					At its current phase, MedMe is a simple platform and does not require excessive font style. However, the platform had inconsistencies with which style was used where and when, resulting in a number of straggling styles.
				</p>
				<p class="p2">
					As a result, my first step was to simplify the font styles across both styleguides for clearer information hierarchy and to reduce inconsistencies.
				</p>
			</div>
		</div>
		<div class="break4"></div>
		<div class="col-12">
			<p class="p2">
				Because MedMe did not have an existing styleguide and the team switched over to Figma, I was given the freedom to create the guide from scratch. Especially when it came to redesigning the patient intake flow, I was able to conceptualize and simplify elements.
			</p>
			<p class="p2">
				However, the pharmacist-facing portal changed very little (primarily due to the request of my manager and the bandwidth of the engineering team). As a result, I learned to meticulously catalogue existing elements, states, and reconcile inconsistencies across similar elements.
			</p>
			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Figma/styleguidegif.gif')} alt=""></img>
			<div class="break3"></div>
			<p class="p2">
				Being the sole designer on the team, I wasn't able to directly impact a design team with these standardized elements. However, it greatly sped up and increased my own quality of work since I was able to work faster, smarter, and change states of elements in the click of a button.
			</p>
			<p class="p2">
				Producing more standardized layouts and designs also greatly impacted my ability to communicate with the developers. Not only were my screens clearer and cleaner, but it allowed me to co-create quickly and seamlessly.
			</p>
			<p class="p2">
				Please note that the following images do not reflect nearly the whole collection of assets that I built. There are many other components (especially pharmacist-facing ones) that are not visualized here.
			</p>
			<div class="break3"></div>
			<img class="img-responsive" src={require('../assets/work/MedMe_Figma/styleguide_large.png')} alt=""></img>
			<div class="break3"></div>

			{/* Closing */}
			<p class="p5">Takeaways</p>
			<h2>Thoughts & Reflections</h2>

			<p class="p2">
				More than anything, being able to standardize MedMe's design library was an incredibly daunting and difficult task that taught me many things. First and foremost, it pushed me to master Figma beyond my previous comfort level and to continually synthesize a multitude of findings into actionable and well-structured assets.
			</p>
			<p class="p2">
				Looking back, I acknowledge my own struggles during the process—the lack of available time and the focus on other projects meant that I was unable to work on this as one dedicated project. While this means that there are things I would've done differently, it allowed me to continually learn more about the system and produce new/refine exisisting assets with emergent skills.
			</p>
			<p class="p2">
				These styleguides are unfortunately incomplete, pointing to the dynamic nature of the MedMe platform. New features are pushed out and conceptualized regularly and any overhauls (which will inevitably happen) will require regular maintenence.
			</p>
			<p class="p2">
				Beyond that, when the library has grown, matured, and become relatively stable, my goal is to have it documented more thoroughly as to cement the design culture and team at MedMe Health.
			</p>
		</div>
	</div>
);
};

export default WearableMedia;
